import { render, staticRenderFns } from "./GroupList.vue?vue&type=template&id=a8424f46&scoped=true"
import script from "./GroupList.vue?vue&type=script&lang=js"
export * from "./GroupList.vue?vue&type=script&lang=js"
import style0 from "./GroupList.vue?vue&type=style&index=0&id=a8424f46&prod&lang=scss&scoped=true"
import style1 from "./GroupList.vue?vue&type=style&index=1&id=a8424f46&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8424f46",
  null
  
)

export default component.exports