<template>
  <div>
    <b-modal
      id="add-new-milking-sidebar"
      centered
      :visible="isAddNewMilkingSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      @ok="$refs.submitButtonRef.click()"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-milking-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            {{ $t("addAnimalGroupMilking") }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->

          <b-form
            ref="refForm"
            class="p-2 modal-form"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <button ref="submitButtonRef" type="submit" class="d-none" />
            <!-- <b-form-group label="Type" label-for="type">
            <v-select
              transition=""
              v-model="addType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group> -->

            <validation-provider
              #default="validationContext"
              name="totalmilkingduration"
              rules="required"
            >
              <b-form-group
                :label="$t('totalmilkingduration')"
                label-for="totalmilkingduration"
              >
                <b-form-input
                  id="totalmilkingduration"
                  v-model="blankMilk.totalmilkingduration"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="earigno"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- totalamount -->
            <validation-provider
              #default="validationContext"
              name="totalamount"
            >
              <b-form-group :label="$t('totalamount')" label-for="totalamount">
                <b-form-input
                  id="totalamount"
                  v-model="blankMilk.totalamount"
                  autofocus
                  type="number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- milkingstartdate -->
            <validation-provider
              #default="validationContext"
              name="milkingstartdate"
              rules="required"
            >
              <b-form-group
                :label="$t('milkingstartdate')"
                label-for="milkingstartdate"
              >
                <b-form-datepicker
                  v-model="blankMilk.milkingstartdate"
                  :locale="$i18n.locale"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- milkingenddate -->
            <validation-provider
              #default="validationContext"
              name="milkingenddate"
              rules="required"
            >
              <b-form-group
                :label="$t('milkingenddate')"
                label-for="milkingenddate"
              >
                <b-form-datepicker
                  v-model="blankMilk.milkingenddate"
                  :locale="$i18n.locale"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t("add") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                {{ $t("cancel") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, getCurrentInstance } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewMilkingSidebarActive",
    event: "update:is-add-new-milking-sidebar-active",
  },
  props: {
    isAddNewMilkingSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedGroup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },

  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);
    const blankMilkData = {
      id: 0,
      companyid: getUserData().companyid,
      plantid: store.state.app.selectedPlantId,
      groupid: props.selectedGroup.id,
      milkingstartdate: dayJs().format(),
      milkingenddate: dayJs().add(1, "day").format(),
      totalmilkingduration: 0,
      totalamount: 0,
    };

    const blankMilk = ref(JSON.parse(JSON.stringify(blankMilkData)));
    const resetblankMilk = () => {
      blankMilk.value = JSON.parse(JSON.stringify(blankMilkData));
    };

    const onSubmit = () => {
      store
        .dispatch("animalsModule/addAnimalGroupMilking", blankMilk.value)
        .then((res) => {
          if (res.statusdata == "-2") {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalAlreadyAdded"),
                icon: "AlertCircleIcon",
                variant: "alert",
              },
            });
          } else if (res.statusdata == "0") {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalAdded"),
                icon: "AlertCircleIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-add-new-milking-sidebar-active", false);
          } else {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalMilkingNotAdded"),
                icon: "AlertCircleIcon",
                variant: "success",
              },
            });
          }
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankMilk);
    return {
      blankMilk,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-milking-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl-scoped {
  width: 70rem;
}
</style>
