<template>
  <b-modal
    id="edit-group-sidebar"
    centered
    :visible="isEditGroupSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="sm"
    backdrop
    no-header
    right
    :title="$t('editGroup')"
    :ok-title="$t('ok')"
    ok-only
    @ok="onSubmit(blankGroupData)"
    @hidden="formValidation(resetblankgroup).resetForm"
    @change="(val) => changed(val)"
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblankgroup
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 modal-form"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <button ref="submitButtonRef" type="submit" class="d-none" />
        <!-- groupName -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="groupName"
          class="w-100"
        >
          <b-form-group :label="$t('groupName')" label-for="groupName">
            <b-form-input
              id="groupName"
              v-model="blankGroupData.groupname"
              autofocus
              :state="
                formValidation(resetblankgroup).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditGroupSidebarActive",
    event: "update:is-edit-group-sidebar-active",
  },
  props: {
    isEditGroupSidebarActive: {
      type: Boolean,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankGroupData: { ...this.group },
      imgStr: "",
      formValidation: formValidation,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
    };
  },
  mounted() {
    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.$refs.previewEl.src = base64;
        this.imgStr = base64;
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  methods: {
    async changed(val) {
      this.imgStr = "";
      this.resetblankgroup();

      this.$emit("update:is-edit-group-sidebar-active", val);
    },
    onSubmit(blankGroupData) {
      this.blankGroupData.plantid = this.$store.state.app.selectedPlantId;

      store.dispatch("animalsModule/updateGroup", blankGroupData).then(() => {
        this.$emit("refetch-data");
        this.$emit("update:is-edit-group-sidebar-active", false);
      });
    },
    async resetblankgroup() {
      this.blankGroupData = {
        ...this.group,
        groupid: this.group.id,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-group-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
